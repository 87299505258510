<template>
  <b-card-code>
    <div v-if="$can('create','Grade Manage')" class="custom-search d-flex">
        <b-button variant="outline-primary" @click="showModal">
          {{ $t('Add new') }}
        </b-button>
    </div>
    <!-- search input -->
    <div class="custom-search d-flex justify-content-end">
      <b-form-group>
        <div class="d-flex align-items-center">
          <label class="mr-1">{{ $t('Search') }}</label>
          <b-form-input
              v-model="searchTerm"
              placeholder="Search"
              type="text"
              class="d-inline-block"
          />
        </div>
      </b-form-group>
    </div>
    <div class="custom-search d-flex justify-content-start">
      <vue-excel-xlsx
          :data="getData"
          :columns="columns10"
          :filename="'filename'"
          :sheetname="'subjects'"
      >
        <b>Excel</b>
      </vue-excel-xlsx>
      <vue-json-to-csv :json-data="getData"
                       :csv-title="'My_CSV'"
      >
        <button>
          <b>CSV</b>
        </button>
      </vue-json-to-csv>
      <button @click="pdfGenerate()">
        <b>PDF</b>
      </button>
    </div>

    <!-- table -->
    <vue-good-table
        style="white-space: nowrap;text-align: center;vertical-align : middle;"
        :columns="columns"
        :rows="grade_types"
        :rtl="direction"
        :search-options="{
        enabled: true,
        externalQuery: searchTerm }"
        :pagination-options="{
        enabled: true,
        perPage:pageLength
      }"
    >
      <template
          slot="table-row"
          slot-scope="props"
      >
        <!-- Column: index -->
        <span v-if="props.column.field === 'id'">
          {{ props.row.originalIndex+1 }}
        </span>
        <!-- Column: Status -->
        <span v-else-if="props.column.field === 'is_active'">
          <template>
            <b-form-checkbox v-if="$can('status change','Subject')" :name="'check-button'+props.row.originalIndex" :id="'check-button'+props.row.originalIndex" @change="statusChange(props.row.id)" v-model="props.row.is_active" :value="1" switch>
            </b-form-checkbox>
            <b-badge v-else :variant="statusVariant(props.row.is_active)">
              {{ props.row.is_active?'Active':'Deactivated' }}
            </b-badge>
          </template>
        </span>

        <!-- Column: Action -->
        <span v-else-if="props.column.field === 'action'">
          <span>
            <template >
                <!-- modal login button -->
              <b-button v-if="$can('read','Grade Manage')" @click="viewInfo(props.row)"
                        variant="secondary" >
                <feather-icon icon="EyeIcon" class="mr-50"/>
              </b-button>
              <b-button v-if="$can('update','Grade Manage')" @click="selectedRow(props.row)"
                  variant="primary" >
                <feather-icon icon="Edit2Icon" class="mr-50"/>
              </b-button>
              <b-button v-if="$can('remove','Grade Manage')" variant="danger" @click="remove(props.row.id)">
                <feather-icon icon="TrashIcon" class="mr-50"/>
              </b-button>
              </template>
          </span>
        </span>
      </template>

      <!-- pagination -->
      <template
          slot="pagination-bottom"
          slot-scope="props"
      >
        <div class="d-flex justify-content-between flex-wrap">
          <div class="d-flex align-items-center mb-0 mt-1">
            <span class="text-nowrap ">
              Showing 1 to
            </span>
            <b-form-select
                v-model="pageLength"
                :options="['10','15','20']"
                class="mx-1"
                @input="(value)=>props.perPageChanged({currentPerPage:value})"
            />
            <span class="text-nowrap"> of {{ props.total }} entries </span>
          </div>
          <div>
            <b-pagination
                :value="1"
                :total-rows="props.total"
                :per-page="pageLength"
                first-number
                last-number
                align="right"
                prev-class="prev-item"
                next-class="next-item"
                class="mt-1 mb-0"
                @input="(value)=>props.pageChanged({currentPage:value})"
            >
              <template #prev-text>
                <feather-icon
                    icon="ChevronLeftIcon"
                    size="18"
                />
              </template>
              <template #next-text>
                <feather-icon
                    icon="ChevronRightIcon"
                    size="18"
                />
              </template>
            </b-pagination>
          </div>
        </div>
      </template>
    </vue-good-table>
    <!-- modal login-->
    <b-modal ref="my-modal" size="lg"
        hide-footer
        :title="model_mode==='add'?$t('Add New'):$t('Edit Information')"
    >
      <!-- form -->
      <validation-observer ref="simpleRules">
        <b-form
            ref="form_add_or_update"
            :style="{height: trHeight}"
            class="repeater-form"
            @submit.prevent="repeateAgain"
        >
        <b-row>
          <b-col md="6">
            <b-form-group>
              <b-card-text>
                <span>{{ $t('Grade Type') }} </span>
              </b-card-text>
              <validation-provider
                  #default="{ errors }"
                  :name="$t('Grade type')"
                  rules="required"
              >
                <b-form-input
                    v-model="name"
                    name="name"
                    :state="errors.length > 0 ? false:null"
                    :placeholder="$t('Grade type')"
                />
                <small class="text-danger">{{ errors[0] }}</small>
              </validation-provider>
            </b-form-group>
          </b-col>
        </b-row>
          <!-- Row Loop -->
          <b-row
              v-for="(item, index) in items"
              :id="item.id"
              :key="item.id"
              ref="row"
          >

            <!-- Mark from -->
            <b-col md="3">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Mark from') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('mark from')"
                    rules="required"
                >
                  <b-form-input
                      v-model.number="item.mark_from"
                      name="mark_from[]"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('mark form')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Mark to -->
            <b-col md="3">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Mark to') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('mark to')"
                    rules="required"
                >
                  <b-form-input
                      v-model.number="item.mark_to"
                      name="mark_to[]"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('mark to')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- gp -->
            <b-col md="2">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('GP') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('gp')"
                    rules="required"
                >
                  <b-form-input
                      v-model.number="item.gp"
                      name="gp[]"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('gp')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- grade name -->
            <b-col md="2">
              <b-form-group>
                <b-card-text>
                  <span>{{ $t('Grade Name') }} </span>
                </b-card-text>
                <validation-provider
                    #default="{ errors }"
                    :name="$t('grade name')"
                    rules="required"
                >
                  <b-form-input
                      v-model="item.grade_name"
                      name="grade_name[]"
                      :state="errors.length > 0 ? false:null"
                      :placeholder="$t('grade name')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>

            <!-- Remove Button -->
            <b-col v-if="items.length>1" md="2" class="mb-50 mt-2">
              <b-button
                  v-ripple.400="'rgba(234, 84, 85, 0.15)'"
                  variant="outline-danger"
                  class="mt-2 mt-md-2"
                  @click="removeItem(index)"
              >
                <feather-icon
                    icon="XIcon"
                    class="mr-25"
                />
                <span></span>
              </b-button>
            </b-col>
            <b-col cols="12">
              <hr>
            </b-col>
          </b-row>
          <b-row>
            <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="repeateAgain"
            >
              <feather-icon
                  icon="PlusIcon"
                  class="mr-25"
              />
              <span>{{ $t('Add More') }}</span>
            </b-button>
            <!-- submit button -->
            <b-col>
              <b-button
                  variant="primary"
                  type="submit"
                  @click.prevent="validationForm"
              >
                {{ $t('Submit') }}
              </b-button>
            </b-col>
          </b-row>
        </b-form>
      </validation-observer>
    </b-modal>
    <b-modal ref="grade-view-modal" size="lg"
        hide-footer
        :title="$t('Grade Information')"
    >
      <vue-good-table
          style="white-space: nowrap;text-align: center;vertical-align : middle;"
          :columns="columns2"
          :rows="single_grades"
          :rtl="direction">
        <template
            slot="table-row"
            slot-scope="props"
        >
          <!-- Column: index -->
          <span v-if="props.column.field === 'id'">
            {{ props.row.originalIndex + 1 }}
          </span>
        </template>
      </vue-good-table>
    </b-modal>

  </b-card-code>
</template>

<script>
import BCardCode from '@core/components/b-card-code/BCardCode.vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BButton,BPagination, BFormGroup, BFormInput, BFormSelect,
  BModal, BForm,BRow, BCol,BCardText,BFormCheckbox,BBadge,
} from 'bootstrap-vue'
import {required} from '@validations'
import { VueGoodTable } from 'vue-good-table'
import store from '@/store/index'
import apiCall from "@/libs/axios";
import Ripple from 'vue-ripple-directive'
import {mapGetters} from "vuex";
import { heightTransition } from '@core/mixins/ui/transition'
import VueExcelXlsx from "vue-excel-xlsx";
import VueJsonToCsv from 'vue-json-to-csv'
import Vue from "vue";
import download from "downloadjs";
Vue.use(VueExcelXlsx);

export default {
  name:'GradeType',
  components: {
    BCardCode,
    VueGoodTable,
    BPagination,BBadge,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BButton,BFormCheckbox,
    BModal,BCardText,
    BForm,BRow, BCol,
    ValidationProvider,
    ValidationObserver, VueJsonToCsv
  },
  directives: {
    Ripple,
  },
  mixins: [heightTransition],
  data() {
    return {
      name: '',
      required,
      pageLength: 10,
      dir: false,
      model_mode:'add',
      selected_row:{},
      columns: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Name',
          field: 'name',
        },
        {
          label: 'Status',
          field: 'is_active',
        },
        {
          label: 'Action',
          field: 'action',
        },
      ],
      searchTerm: '',
      items: [{
        id: 1,
        mark_from: 0,
        mark_to: 0,
        gp: 0.00,
        grade_name: '',
        prevHeight: 0,
      }],
      nextTodoId: 2,
      columns2: [
        {
          label: 'SL',
          field: 'id',
        },
        {
          label: 'Mark From',
          field: 'mark_from',
        },
        {
          label: 'Mark To',
          field: 'mark_to',
        },
        {
          label: 'GP',
          field: 'gp',
        },
        {
          label: 'Grade Name',
          field: 'grade_name',
        },
      ],
      single_grades:[],
      columns10: [
        {
          label: 'Name',
          field: 'Name',
        },
        {
          label: 'Status',
          field: 'Status',
        }
      ],
    }
  },
  methods:{
    selectedRow(row){
      this.selected_row=row;
      this.name=row.name;
      this.items=JSON.parse(row.grades);
      this.model_mode='edit';
      this.$refs['my-modal'].show()
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          let data =new FormData();
          data.append('name',this.name);
          data.append('grades',JSON.stringify(this.items));
          if (this.model_mode ==='add'){
            apiCall.post('/grade/type/store',data).then((response)=>{
              this.hideModal();
              this.$store.dispatch('GET_ALL_GRADE_TYPE');
              this.$toaster.success(response.data.message);
            }).catch((error)=>{
              if (error.response.status == 422) this.$toaster.error(error.response.data.errors.name);
              else this.$toaster.error(error.response.data.message);
            });
          }else {
            apiCall.post(`/grade/type/update${this.selected_row.id}`,data).then((response)=>{
              this.hideModal();
              this.$toaster.success(response.data.message);
              this.$store.dispatch('GET_ALL_GRADE_TYPE');
            }).catch((error)=>{
              if (error.response.status == 422) this.$toaster.error(error.response.data.errors.name);
              else this.$toaster.error(error.response.data.message);
            });
          }
        }
      })
    },
    statusChange(id){
      this.$swal({
        title: 'Are you sure?',
        text: "You want to change status!",
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, change it!',
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          apiCall.put(`/grade/type/status/change${id}`).then((response)=>{
            this.$store.dispatch('GET_ALL_GRADE_TYPE');
            this.$swal({
              icon: 'success',
              title: 'Success!',
              text: response.data.message,
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }).catch((error)=>{
            this.$toaster.error(error.response.data.message);
          });
        }
      })
    },
    remove(id){
        this.$swal({
          title: 'Are you sure?',
          text: "You want to delete this!",
          icon: 'warning',
          showCancelButton: true,
          confirmButtonText: 'Yes, delete it!',
          customClass: {
            confirmButton: 'btn btn-primary',
            cancelButton: 'btn btn-outline-danger ml-1',
          },
          buttonsStyling: false,
        }).then(result => {
          if (result.value) {
            apiCall.delete(`/grade/type/remove${id}`).then((response)=>{
              this.$store.dispatch('GET_ALL_GRADE_TYPE');
              this.$swal({
                icon: 'success',
                title: 'Deleted!',
                text: response.data.message,
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            }).catch((error)=>{
              this.$toaster.error(error.response.data.message);
            });
          }else if (result.dismiss === 'cancel') {
            this.$swal({
              title: 'Cancelled',
              text: 'Your date is safe :)',
              icon: 'error',
              customClass: {
                confirmButton: 'btn btn-success',
              },
            })
          }
        })
    },
    showModal() {
      this.$refs['my-modal'].show()
      this.selected_row={};
      this.name='';
      this.items= [{
        id: 1,
        mark_from: 0,
        mark_to: 0,
        gp: 0.00,
        grade_name: '',
        prevHeight: 0,
      }];
      this.nextTodoId= 2;
      this.model_mode='add';
    },
    hideModal() {
      this.$refs['my-modal'].hide()
    },
    repeateAgain() {
      this.items.push({
        id: this.nextTodoId += this.nextTodoId,
      })

      this.$nextTick(() => {
        this.trAddHeight(this.$refs.row[0].offsetHeight)
      })
    },
    removeItem(index) {
      this.items.splice(index, 1)
      this.trTrimHeight(this.$refs.row[0].offsetHeight)
    },
    initTrHeight() {
      this.trSetHeight(null)
      this.$nextTick(() => {
        this.trSetHeight(this.$refs.form_add_or_update.scrollHeight)
      })
    },
    viewInfo(row){
      try{
        this.single_grades=JSON.parse(row.grades)
      }catch (e) {
        this.single_grades=[];
      }
      this.$refs['grade-view-modal'].show()
    },
    pdfGenerate() {
      let title = "Grade Types";
      let clm = ['Name','Status'];
      let data = new FormData();
      data.append('title', title);
      data.append('columns', JSON.stringify(clm));
      data.append('data', JSON.stringify(this.getData));
      apiCall.post('/get/common/table/export/pdf', data, {responseType: 'blob'}).then((response) => {
        const content = response.headers['content-type'];
        download(response.data, 'grade_types.pdf', content)
      }).catch((error) => {
        this.$toaster.error(error.response.data.message);
      });
    },
  },
  computed: {
    statusVariant() {
      const statusColor = {
        /* eslint-disable key-spacing */
        1 : 'light-success',
        0 : 'light-danger',
        /* eslint-enable key-spacing */
      }

      return status => statusColor[status]
    },
    direction() {
      if (store.state.appConfig.isRTL) {
        // eslint-disable-next-line vue/no-side-effects-in-computed-properties
        this.dir = true
        return this.dir
      }
      // eslint-disable-next-line vue/no-side-effects-in-computed-properties
      this.dir = false
      return this.dir
    },
    ...mapGetters(['grade_types']),
    getData() {
      return this.grade_types.map(item => ({
        Name: item.name ? item.name : '',
        Status: item.is_active ? 'Active' : 'Inactive'
      }));
    },
  },
  mounted() {
    this.initTrHeight()
  },
  created() {
    window.addEventListener('resize', this.initTrHeight);
    if(this.grade_types.length <1) this.$store.dispatch('GET_ALL_GRADE_TYPE');
  },
  destroyed() {
    window.removeEventListener('resize', this.initTrHeight)
  },
}
</script>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-sweetalert.scss';
.repeater-form {
  overflow: hidden;
  transition: .35s height;
}
</style>